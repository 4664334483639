import { urls as urls } from '../api/urls';
import axios from 'axios';


export function updateUser(data) {
    return {
        type: 'UPDATE_USER',
        data
    }
}

export function loginUser() {
    return {
        type: 'LOG_IN_USER'
    }
}

export function logoutUser() {
    return {
        type: 'LOG_OUT_USER'
    }
}


export function updateProfile(data){
    return{
        type: 'UPDATE_PROFILE',
        data
    }
}

export function updateLicense(data){
    return{
        type: 'UPDATE_LICENSE',
        data
    }
}

export function flushUserState(){
    return {
        type: 'FLUSH_USER_STATE'
    }
}

export function updateFcmToken(token){
    return {
        type: 'UPDATE_FCM_TOKEN',
        data: token
    }
}

export function fetchProfile() {
    return function (dispatch, getState) {
        if(getState().user.phone === ''){
            return;
        }
        let url = urls.profile;
        let data = {
            "mobileNo": getState().user.phone
        }
        axios.post(url, data)
            .then(response => {
                if (response.data.statusCD === 200) {
                    dispatch(updateProfile(response.data.responseData));
                }
            })
    }
}


export function fetchLastTripRating(success, error){
    return function (dispatch, getState) {
        let url = urls.lastTripRating;
        let data = {
            "id": getState().user.id
        }
        console.log(data);
        axios.post(url, data)
            .then(response => {
                console.log(response);
                if (response.data.statusCD === 200) {
                    success(response.data);
                }
                else if(response.data.statusCD === 404){
                    success(response.data);
                }
            })
            .catch(err => error(err))
    }
}


export function updateRating(rating, bidId, vendorId, success, error){
    return function (dispatch, getState) {
        let url = urls.fetchOrUpdateRating;
        let data = {
            "isUpdate": true,
            "bidId": bidId,
            "vendorId":vendorId,
            "vendorRating":rating
        }
        console.log(data);
        axios.post(url, data)
            .then(response => {
                
                if (response.data.statusCD === 200) {
                    success(response.data.responseData);
                    // dispatch(updateProfile(response.data.responseData));
                }
                else if(response.data.statusCD === 404){
                    success(response.data.responseData);
                }
            })
            .catch(err => {;console.log(err);error(err)})
    }
}

